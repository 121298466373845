import React from "react";

import {CheckCircle, InfoCircle}              from "react-bootstrap-icons";
import {OverlayTrigger, Popover} from "react-bootstrap";

import EmailFormatter       from "../../../formatters/EmailFormatter";
import ShortTimeFormatter   from "../formatters/TimeFormatter";
import {ShortDateFormatter} from "../../../formatters/DateFormatter";

const Event = (event) => {
	const popover = (
		<Popover>
			<Popover.Header as="h3">{event?.title}</Popover.Header>
			<Popover.Body>
				<section className="row">
					<section className="col-md-5">Termin</section>
					<section className="col-md-9">{ShortDateFormatter(event.event?.start)}</section>
				</section>
				<section className="row">
					<section className="col-md-5">Beginn</section>
					<section className="col-md-9">{`${ShortTimeFormatter(event.event?.start)}`}</section>
				</section>
				<section className="row">
					<section className="col-md-5">Ende</section>
					<section className="col-md-9">{`${ShortTimeFormatter(event.event?.end)}`}</section>
				</section>
				<section className="row">
					<section className="col-md-5">Bewirtung</section>
					<section className="col-md-9">{`${event.event.hospitality?"ja":"nein"}`}</section>
				</section>
				<section className="row">
					<section className="col-md-5">Bestätigt</section>
					<section className="col-md-9">{`${event.event.confirmed?"ja":"nein"}`}</section>
				</section>
				<section className="row">
					<section className="col-md-5">Kontakt</section>
					<section className="col-md-9">{`${event.event?.contact}`}</section>
				</section>
				<section className="row">
					<section className="col-md-9">Email</section>
					<section className="col-md-9">{EmailFormatter(event.event?.email)}</section>
				</section>
			</Popover.Body>
		</Popover>
	)

    return (
        <>
		<OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose={true}>
            <div>
				{event.event.confirmed?<CheckCircle style={{marginRight: "5px"}}/>:<InfoCircle style={{marginRight: "5px"}}/>}
				<label style={{marginBottom: "0"}}>{`${ShortTimeFormatter(event.event?.start).replace("Uhr", "")} - ${ShortTimeFormatter(event.event?.end).replace("Uhr","")}`}</label>
			</div>
		</OverlayTrigger>
        </>
    )
}

export default Event;