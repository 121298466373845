import React, {useState} from "react";

import {ArrowBarLeft, ArrowBarRight, BoxArrowLeft, Gear} from "react-bootstrap-icons";

import AppInfo from "../../package.json";

import ClientSettingsModal from "./ClientSettingsModal";

const Sidebar = ({modules, logout, cSettings, changeClientSettings}) => {
	const [clientSettings]                = cSettings;
	const [showSettings, setShowSettings] = useState(false);

	return (
		<aside className={`main bg-primary text-white ${clientSettings.sidebarVisible ? "" : "minimized"}`}>
			<nav className="sidebar">
				<section className="navbar-brand"><img src="/icon_marvolo_white.svg" alt="marvolo. Logo" /> marvolo.</section>
				{Object.entries(modules).sort(([aName], [bName]) => aName === "Dashboard" ? -1 : aName.localeCompare(bName)).map(module => {
					const [name, Component] = module;
					/**
					 * @deprecated type="nav" will be no longer supported, use `ìf (nav)`
					 */
					return <Component key={name} type="nav" nav={true} />
				})}
				<li onClick={logout}><BoxArrowLeft /> <span>Logout</span></li>
			</nav>
			<p className="version">
				<small>v{AppInfo.version}_6339-dev</small>
			</p>
			<section className="controls">
				<button className="btn text-white" onClick={() => setShowSettings(true)}>
					<Gear />
				</button>
				<button className="btn text-white" onClick={() => changeClientSettings("sidebarVisible", !clientSettings.sidebarVisible)}>
					{ clientSettings.sidebarVisible ? <ArrowBarLeft /> : <ArrowBarRight />}
				</button>
			</section>
			<ClientSettingsModal show={showSettings} setShow={setShowSettings} cSettings={cSettings} changeClientSettings={changeClientSettings} />
		</aside>
	);
};

export default Sidebar;
