import React, {useMemo} from "react";

import Modal                from "components/shared/Modal";
import SortableTable        from "components/shared/SortableTable";
import Spinner              from "components/shared/Spinner";
import {ShortDateFormatter} from "formatters/DateFormatter";

import ShortTimeFormatter    from "../../formatters/TimeFormatter";
import useRMStore            from "../../hooks/useRMStore";

const Header = [
	{
		label      : "Titel",
		member     : "name"
	},
    {
        label      : "Termin",
        member     : "from",
        sortable   : true,
        formatter  : ShortDateFormatter,
    },
	{
		label      : "Anfang",
		member     : "from",
		formatter  : ShortTimeFormatter
	},
	{
		label      : "Ende",
		member     : "to",
		formatter  : ShortTimeFormatter
	},
	{
		label      : "Anfrage von",
		member     : "contactName",
        functional : ({member}) =>  {return <a href={`mailto:${member.contactEmail}`}>{member.contactName}</a>}
	}
]


const RoomsBookingList = ({onHide, show, currentRoom}) => {
	const bookings = useRMStore(store => store.bookings);
	const ready    = useRMStore(store => store.ready);

	const filteredBookings = useMemo(() => {
        return bookings.filter((booking) => {
			return booking.room?.id === currentRoom?.id
		});
	}, [currentRoom, bookings]);

    return(
        <>
            <Modal size="xl" onHide={onHide} show={show} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Belegte Zeiträume für '${currentRoom?.name}'`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
					<section className="p-4">
						{
							ready ? 
								<SortableTable data={filteredBookings} headers={Header}/>
							: <Spinner/>
						}
					</section>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-danger" onClick={onHide}>Schließen</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RoomsBookingList;