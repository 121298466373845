import create  from "zustand";
import {immer} from "zustand/middleware/immer";
import axios   from "axios";

import {StrapiRequest, StrapiResponse} from "utils/StrapiUtils";

const RecurringType = Object.freeze({
	DAILY   : "daily",
	WEEKLY  : "weekly",
	MONTHLY : "monthly",
	YEARLY  : "yearly"
});

const useRMStore = create(immer((set, get) => ({
	ready    : false,
	readySet : ready => set({ready}),

	deleting    : false,
	deletingSet : deleting => set({deleting}),

	rooms         : [],
	roomsSet      : rooms => set({rooms}),
	roomsReady    : false,
	roomsReadySet : roomsReady => set({roomsReady}),
	roomsLoad     : alert => {
		axios.get("/rb-rooms?populate=*&pagination[limit]=-1").then(response => {
			set({rooms : StrapiResponse(response), roomsReady : true});
		}).catch(error => alert.error(`Fehler beim Laden der Räume: ${error}`))
	},

	room     : null,
	roomSet  : room => set({room}),
	roomSave : (alert, formData, callback) => {
		const {room} = get();

		const saveMethod = room ? axios.put : axios.post;
		const saveURL    = room ? `/rb-rooms/${room.id}` : "/rb-rooms";

		saveMethod(saveURL, StrapiRequest(formData)).then(response => {
			const {rooms} = get();
			if (room) {
				set({rooms : rooms.map(item => {
					if (item.id !== room.id)
						return item;
					return {...formData};
				})});
			} else {
				set({rooms : [...rooms, StrapiResponse(response)]});
			}
			alert.success(`Der Raum '${formData.name}' wurde erfolgreich ${room ? "aktualisiert" : "angelegt"}.`)
			callback();
		}).catch((error) => {
			alert.error(`Fehler beim ${room ? "Aktualisieren" : "Anlegen"} des Raums '${formData.name}': ${error}`)
			console.log(error);
		})
	},
	roomDelete : alert => {
		const {room, rooms} = get();

		if (!room)
			return;

		axios.delete(`/rb-rooms/${room.id}`).then(() => {
			alert.success(`Der Raum '${room.name}' wurde erfolgreich gelöscht.`);
			set({room : null, rooms : rooms.filter(item => item.id !== room.id)});
		}).catch(error => alert.error(`Fehler beim Löschen des Raumes: ${error}`));
	},

	booking    : null,
	bookingSet : booking => set({booking}),

	bookings     : [],
	bookingsSet  : bookings => set({bookings}),
	bookingsLoad : alert => {
		const parameter = { _sort: "createdAt:desc" };
		axios.get("/rm-bookings/?populate=*&pagination[limit]=-1&pagination[start]=0", StrapiRequest({ params: parameter })).then(response => {
			set({bookings : StrapiResponse(response), ready : true});
		}).catch(error => alert.error(`Fehler beim Laden der Buchungen: ${error}`));
	},
	bookingsSave  : (alert, formData, callback) => {
		const {bookings, booking, pattern, rooms, series} = get();

		const saveMethod = booking ? axios.put : axios.post;
		const saveURL    = booking ? `/rm-bookings/${booking.id}` : "/rm-bookings";

		saveMethod(saveURL, StrapiRequest(formData)).then((response) => {
			if (typeof formData.room === "string") {
				const room = rooms.filter(item => item.id === Number(formData.room))[0] ?? "";
				formData.room = room;
			}
			const newBooking = {...StrapiResponse(response), ...formData};

			console.log("FORM", formData);
			console.log("BOOK", booking);
			console.log("NEW", newBooking);
			
			
			
			if (series && !booking) {
				const booking_id = response.data.id;
				axios.post("/rm-recurrings", { data: { ...pattern, rm_bookings: [booking_id] } }).catch((error) => {
					alert.error(`Fehler beim Erstellen der Serienbuchung: ${error}`);
				});
			}

			if (booking)
				set({bookings : bookings.map(item => item.id === booking.id ? newBooking : item)});
			else
				set({bookings : [...bookings, newBooking]});

			callback();
			alert.success(`Raumbuchung erfolgreich ${booking ? "aktualisiert" : "angelegt"}.`);
		}).catch(error => {
			alert.error(`Fehler beim ${booking ? "Aktualisieren" : "Anlegen"} der Buchung: ${error.response.error.message}`);
		});
	},
	bookingsDelete : (alert, callback) => {
		const {bookings, booking, confirmToggle, deletingSet} = get();
		
		if (!booking)
			return;

		if (booking?.confirmed)
			confirmToggle();

		deletingSet(true);

		axios.delete(`/rm-bookings/${booking.id}`).then(() => {
			set({booking: null, bookings : bookings.filter(item => item.id !== booking.id)});
			callback();
			deletingSet(false);
			alert.success("Raumbuchung erfolgreich gelöscht");
		}).catch((error) => {
			deletingSet(false);
			alert.error(`Fehler bei der Löschung der Buchung: ${error}`);
		})
	},

	confirmToggle : (alert, callback = () => {}) => {
		const {booking, bookings} = get();

        if (!booking)
            return;

        const copy = {...booking};
        copy.confirmed = !booking.confirmed;

        axios.put(`/rm-bookings/confirm/${copy.id}`, StrapiRequest(copy)).then(() => {
            alert.success(`Raumbuchung erfolgreich ${copy.confirmed === true ? "bestätigt" : "zurückgenommen"}`);
			set({bookings : bookings.map(item => item.id === booking.id ? copy : item)});
			callback();
        }).catch(error => {
			console.error(error);
            alert.error(`Fehler bei der Bestätigung der Buchung: ${error?.response?.data?.message}`);
        })
	},

	pattern : {
		recurring_type   : RecurringType.WEEKLY,
		separation_count : 0,                      //number of type between bookings e.g. recurring_type="weekly" separation_count=1 means every 2 Weeks (1 Week between bookings)
		end_date         : new Date().toISOString
	},
	patternSet : pattern => set({pattern}),

	refresh : alert => {
		const {bookingsLoad} = get();
		set({ready : false});
		bookingsLoad(alert);
	},

	series    : false,
	seriesSet : series => set({series})
})));

export default useRMStore;