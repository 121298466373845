import React, {useState, useEffect, useMemo} from "react";

import {CheckSquare, XSquare, Pencil, CheckCircle, Clipboard2PlusFill, CupFill, FileArrowDown} from "react-bootstrap-icons";

import {useAlert} from "react-alert";

import DateInput                       from "components/shared/DateInput";
import NoResult                        from "components/shared/NoResult";
import PageHeader                      from "components/shared/PageHeader";
import SearchField                     from "components/shared/SearchField";
import Select                          from "components/shared/Select";
import SortableTable                   from "components/shared/SortableTable";
import Spinner                         from "components/shared/Spinner";
import {ShortDateFormatter}            from "formatters/DateFormatter";
import Toggle                          from "components/shared/Toggle";
import useLocalStorage                 from "components/hooks/useLocalStorage";

import useRMStore         from "../hooks/useRMStore"; 
import BookingsEdit       from "./modals/BookingsEdit";
import Confirmation       from "./modals/Confirmation";
import ShortTimeFormatter from "../formatters/TimeFormatter";

const BookingsList = () => {
	const alert                                   = useAlert();
	const ready                                   = useRMStore(store => store.ready);
	const rooms                                   = useRMStore(store => store.rooms);
	const bookings                                = useRMStore(store => store.bookings);
	const refresh                                 = useRMStore(store => store.refresh);
	const [filteredBookings, setFilteredBookings] = useState([]);
	const [filterRoom, setFilterRoom]             = useState(null);
	const [filterDateFrom, setFilterDateFrom]     = useState("");
	const [filterDateTo, setFilterDateTo]         = useState("");
	const [showModal, setShowModal]               = useState(false);
	const [showConfirm, setShowConfirm]           = useState(false);
	const [search, setSearch]                     = useState("");
	const [booking, setBooking]                   = useRMStore(store => [store.booking, store.bookingSet]);
	const [showOldBookings, setShowOldBookings]   = useLocalStorage("roommanager-showOldBookings", false);
	const handleModalClose                        = (setter) => setter(false);

	const Header = useMemo(() => [
		{
			label    : "Titel",
			member   : "name",
			sortable : false,
			width    : "130px"
		},
		{
			label     : "Angefragt am",
			member    : "createdAt",
			formatter : ShortDateFormatter,
			width     : "130px"
		},
		{
			label     : "Termin",
			member    : "from",
			formatter : ShortDateFormatter,
			width     : "90px"
		},
		{
			label      : "Von - Bis",
			member     : null,
			sortable   : false,
			functional : ({ member }) => {
				return <div style={{ "marginLeft": "0.75rem" }}>
					{ShortTimeFormatter(member.from)} - {ShortTimeFormatter(member.to)}
				</div>
			},
			width      : "110px"
		},
		{
			label    : "Raum",
			member   : "room.slug",
			sortable : false,
			width    : "100px"
		},
		{
			label      : "Anfrage von",
			member     : "contactName",
			sortable   : false,
			functional : ({ member }) => {
				return <a href={`mailto:${member.contactEmail}`} style={{ "marginLeft": "0.75rem" }}>{member.contactName}</a>
			},
			width      : "140px"
		},
		{
			label      : "Bewirtung",
			member     : "hospitality",
			width      : "110px",
			sortable   : false,
			functional : ({member}) => {
				return member.hospitality ? <CupFill className="text-success" style={{ opacity: 1, "marginLeft": "0.75rem" }} /> : <></>;
			}
		},
		{
			label      : "Status",
			width      : "110px",
			member     : "confirmed",
			functional : ({member}) => {
				return (
					<>
						{
							member.confirmed ?
								<CheckSquare style={{ opacity: 1, "marginLeft": "0.75rem" }} className="text-success" />
								:
								<XSquare style={{ opacity: 1, "marginLeft": "0.75rem" }} className="text-danger" />
						}
					</>
				)
			}
		},
		{
			label      : "",
			member     : null,
			width      : "150px",
			sortable   : false,
			functional : ({member}) => {
				return (
					<>
						<button className="btn">
							<Pencil onClick={() => {
								setBooking(member);
								setShowModal(true);
							}}
							/>
						</button>
						<button className="btn">
							<CheckCircle onClick={() => {
								setBooking(member);
								setShowConfirm(true);
							}} />
						</button>
					</>
				)
			}
		}
	], [setBooking]);

	const handleSelect = (event) => {
		setFilterRoom(event.target.value)
	}

	const handleDateInput = (event) => {
		if (event.target.name === "from") {
			if (event.target.value !== "")
				setFilterDateFrom(new Date(event.target.value));
			else
				setFilterDateFrom("");
		}
		if (event.target.name === "to") {
			if (event.target.value !== "") {
				//Setting Date to 1 Day ahead because selected time in Datepicker is 00:00:00
				const to = new Date(event.target.value);
				to.setDate(to.getDate() + 1);
				setFilterDateTo(to);
			} else {
				setFilterDateTo("");
			}
		}
	}

	/**Filtering */

	useEffect(() =>{
		const today = new Date(Date.now());
		const regex = new RegExp(`${search}`, "gi");		
		setFilteredBookings(bookings.filter( booking => 
			{

				//Text Search
				if(search !== "" && !(booking.name.match(regex) || booking.contactName.match(regex)))
						return false;

				//Room Filter
				if(filterRoom && (booking.room.id !== parseInt(filterRoom)))
 					return false;

				
				// Date Filter
				const bookingDate = new Date(booking.from);

				if (filterDateFrom !== "" && filterDateTo !== "") {
					if((bookingDate <= filterDateFrom) || (bookingDate >= (filterDateTo)))
						return false;
				}

				if (filterDateFrom !== "" && (bookingDate <= filterDateFrom)) 
						return false;

				if (filterDateTo !== "" && (bookingDate >= filterDateTo)) 
						return false;

				//Toggle Past Events
				if(!showOldBookings && (bookingDate < today))
					return false;

				return true;
			}
		))
	},[search,filterRoom,filterDateFrom,filterDateTo,showOldBookings,bookings])
	
	

	return (
		<>
			<PageHeader title="Liste der Buchungen" textNew="Neue Raumbuchung anlegen" Icon={Clipboard2PlusFill} actionNew={() => { setBooking(null); setShowModal(true); }}>
				<section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />
					<section>
						<button className="btn btn-outline-primary" onClick={() => refresh(alert)} disabled={!ready}><FileArrowDown /> Buchungen abholen</button>
					</section>
				</section>
				<section className="grid four-fourths">
					<Select data={rooms.filter((room) => room?.active)} value={filterRoom || ""} onChange={handleSelect} firstSelectable={true} defaultText={"Alle Räume"} />
					<DateInput className="form-control" placeholder="Von" name="from" onChange={handleDateInput} value={filterDateFrom || ""} event={true} />
					<DateInput className="form-control" placeholder="Bis" name="to" onChange={handleDateInput} value={filterDateTo || ""} event={true} />
					<section>
							<Toggle name="oldBookings" checked={showOldBookings} onChange={event => setShowOldBookings(event.target.checked)}/>
							<label className="ml-2" style={{ alignSelf: "center",margin: 0, justifySelf: "left" }}>zeige auch vergangene Termine </label>
					</section>
				</section>
			</PageHeader>

			{
				ready ?
					<> 
						{filteredBookings.length > 0 ? 
							<SortableTable headers={Header} data={filteredBookings} sorting="createdAt" rowCallback={booking => { setBooking(booking); setShowConfirm(true); }} />
						: <NoResult />}
					</>
					: <Spinner />
			}
			<BookingsEdit show={showModal} setShow={setShowModal} rooms={rooms} />
			<Confirmation show={showConfirm} setShow={setShowConfirm} onHide={() => handleModalClose(setShowConfirm)} bookings={bookings} />
		</>
	)
};

export default BookingsList;
