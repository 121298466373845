import React from "react";

import {useAlert}              from "react-alert";
import {ExclamationSquareFill} from "react-bootstrap-icons";

import Modal                from "components/shared/Modal";

import ShortTimeFormatter   from "../../formatters/TimeFormatter";
import {ShortDateFormatter} from "formatters/DateFormatter";

import useRMStore from "../../hooks/useRMStore";

const Confirmation = ({show, onHide, setShow, bookings}) => {
	const alert          = useAlert();
	const confirmToggle  = useRMStore(store => store.confirmToggle);
	const currentBooking = useRMStore(store => store.booking);

    const handleConfirmation = () => {
		confirmToggle(alert, () => {
            setShow(false);
		});
    }

    function CheckForCollision() {
        let   collison    = null;
        const currentFrom = new Date(currentBooking?.from);
        const currentTo   = new Date(currentBooking?.to);

        currentFrom.setSeconds(0);
        currentTo.setSeconds(0);

        // 1 Minute tollerance so an event fom 10:00-12:00 does not collide with 09:00-10:00
        currentFrom.setMinutes(currentFrom.getMinutes() + 1);
        currentTo.setMinutes(currentTo.getMinutes() - 1);
        
        bookings.forEach(event => {
            const eventFrom = new Date(event?.from);
            const eventTo   = new Date(event?.to);
            
            eventFrom.setSeconds(0);
            eventTo.setSeconds(0);

            if ((event?.room?.id === currentBooking?.room?.id) && (event?.id !== currentBooking?.id) && event.confirmed) {
                if ((currentFrom >= eventFrom && currentFrom <= eventTo) || (currentTo >= eventFrom && currentTo <= eventTo) || (currentFrom < eventFrom && currentTo > eventTo)) { //starts during other event || ends during other event || starts before and ends after other event
                    console.log("Warning collison with " + event.name);
                    collison = <>Zur gleichen Zeit wie: {event.name}</>;
                }
            }
            

        });
        if (collison) {
			return (
				<>
					<label className="collisionWarning">Kollision <ExclamationSquareFill></ExclamationSquareFill></label>
					<label className="collisionWarning">{collison}</label>
				</>
			);
		} else {
        	return null;
		}
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>{`${currentBooking?.confirmed === true ? "Buchung zurücknehmen" : "Raumbuchung bestätigen"}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <section className="grid form">
                    <CheckForCollision></CheckForCollision>
                    <label>Antragsteller/in</label>
                    <label>{currentBooking?.contactName}</label>

                    <label>Raum</label>
                    <label>{currentBooking?.room?.name} ({currentBooking?.room?.slug})</label>

                    <label>Titel</label>
                    <label>{currentBooking?.name}</label>

                    <label>Datum</label>
                    <label>{`${ShortDateFormatter(currentBooking?.from)}`}</label>

                    <label>Anfang</label>
                    <label>{`${ShortTimeFormatter(currentBooking?.from)}`}</label>

                    <label>Ende</label>
                    <label>{`${ShortTimeFormatter(currentBooking?.to)}`}</label>

                    <label>Teilnehmer</label>
                    <label>{currentBooking?.participants}</label>

                    <label>Mit Bewirtung</label>
                    <label>{currentBooking?.hospitality ? "Ja" : "Nein"}</label>

                    <label>Bemerkung</label>
                    <label>{currentBooking?.notes}</label>

                    <label>E-Mail-Adresse</label>
                    <label><a href={`mailto:${currentBooking?.contactEmail}`}>{currentBooking?.contactEmail}</a></label>
                </section>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={() => { setShow(false) }}>Abbrechen</button>
                {
                    currentBooking?.confirmed ?
                        <>
                            <button className="btn btn-danger" onClick={handleConfirmation}>Buchung zurücknehmen</button>
                        </>
                        :
                        <button className="btn btn-success" onClick={handleConfirmation}>Buchung bestätigen</button>
                }
            </Modal.Footer>
        </Modal>
    )

}

export default Confirmation