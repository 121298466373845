import React, {useEffect} from "react";

import {useAlert} from "react-alert";

import useRMStore from "../../hooks/useRMStore";
import Modal      from "components/shared/Modal"
import useForm    from "components/hooks/useForm";
import Toggle     from "components/shared/Toggle";

const DefaultFormData = {
	name     : "",
	slug     : "",
	capacity : "",
	active   : true,
	location : "",
	extras   : "",
	notify   : "",
	catering : ""
};

const RoomsEdit = ({show, setShow, onHide, currentRoom}) => {
	const alert                          = useAlert();
	const save                           = useRMStore(store => store.roomSave);
	const [formData, handleInput, clear] = useForm(DefaultFormData);


	useEffect(() => {
		if (!currentRoom)
			clear();
		else{
			clear(() => ({...currentRoom}));
		}
	}, [currentRoom, clear]);

	return (
		<>
			<Modal show={show} onHide={onHide} size="lg" scrollable={true}>
				<Modal.Header>
					<Modal.Title>{currentRoom?formData.name:'Neuer Raum'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<section className="grid form">
						<label>Aktiv</label>
						<Toggle name="active" checked={formData.active} onChange={handleInput} label="" id="active" />

						<label>Name</label>
						<input className="form-control" name="name" onChange={handleInput} value={formData.name} required/>

						<label>Raumnummer</label>
						<input className="form-control" name="slug" onChange={handleInput} value={formData.slug} required/>
						
						<label>Personenkapazität</label>
						<input className="form-control" type="number" name="capacity" onChange={handleInput} value={formData.capacity} />

						<label>Raumverwaltung</label>
						<input className="form-control" name="notify" onChange={handleInput} value={formData?.notify} />

						<label>Bewirtung</label>
						<input className="form-control"  name="catering" onChange={handleInput} value={formData?.catering} />

						<label>Ort</label>
						<input className="form-control" name="location" onChange={handleInput} value={formData.location}/>

						<label>Raum Informationen</label>
						<textarea className="form-control" name="extras" onChange={handleInput} value={formData.extras} rows="5"/>
					</section>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-outline-danger" onClick={() => {
						clear(); 
						setShow(false);
					}}>Abbrechen</button>
					<button className="btn btn-success" onClick={() => save(alert, formData, () => {setShow(false); clear();})}>Raum {currentRoom ? "speichern" : "anlegen"}</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default RoomsEdit;
